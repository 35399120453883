<template>
  <div class="l-wrapper">
    <div class=" q-pa-md q-gutter-sm">
      <q-select
        outlined
        :options="data.year"
        v-model="data.selDate.year"
        label="Year"
      ></q-select>
      <q-select
        outlined
        map-options
        emit-value
        :options="data.month"
        v-model="data.selDate.month"
        label="Month"
      ></q-select>
    </div>
    <q-scroll-area class="l-grow">
      <q-list separator>
        <q-item
          class="bg-grey-4 q-py-lg"
          v-for="(val, i) in data.monthly_sales"
          :key="i"
        >
          <q-item-section>
            <div class="column q-gutter-sm">
              <q-item-label>{{ val.username }}</q-item-label>

              <q-field
                outlined
                v-model="val.qty"
                class="bg-white"
                label="Qty (Kg)"
              >
                <template
                  v-slot:control="{ id, floatingLabel, modelValue, emitValue }"
                >
                  <money3comp
                    :id="id"
                    class="q-field__input"
                    :model-value="modelValue"
                    @update:model-value="emitValue"
                    v-bind="config"
                    v-show="floatingLabel"
                  ></money3comp>
                </template>
              </q-field>
              <q-field
                outlined
                v-model="val.value"
                class="bg-white"
                label="Value (Rp)"
              >
                <template
                  v-slot:control="{ id, floatingLabel, modelValue, emitValue }"
                >
                  <money3comp
                    :id="id"
                    class="q-field__input"
                    :model-value="modelValue"
                    @update:model-value="emitValue"
                    v-bind="config"
                    v-show="floatingLabel"
                  ></money3comp>
                </template>
              </q-field>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <q-btn label="Save" color="primary" @click="addMonthlySales()"></q-btn>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { Money3Component } from "v-money3";
import useMonthlySales from "./useMonthlySales";
export default {
  components: { money3comp: Money3Component },
  setup() {
    const { data, getSales, addMonthlySales, $q } = useMonthlySales();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getSales();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let config = ref({
      prefix: "",
      suffix: "",
      thousands: ".",
      decimal: ",",
      precision: 0,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
    });

    return {
      data,
      addMonthlySales,
      config,
    };
  },
};
</script>
